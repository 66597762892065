/* 
* Profile Card main container
*/
.profileCardAvatarSkeleton {
  padding: 24px;

  .profileCardAvatar {
    display: flex;
    align-items: center;
  }

  .profileCardContainer {
    display: flex;
    align-items: center;
    justify-items: center;
  }
}

/* 
* Profile Card first subtitle
*/
.profileCardSubTitle1 {
  padding: 8px 0px 0px 0px;
}

/* 
* Profile Card second subtitle
*/
.profileCardSubTitle2 {
  margin: 16px 0px 0px 0px;
}
