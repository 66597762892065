/* 
! Main Navigation Bar Style 
*/
.navBarStyle {
  // overflow: hidden;
  height: 80px;
  background: #171717;
  // display: flex;
  /* 
!  Navigation Bar Logo Style 
*/
  .navBarColStyle {
    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
  }
  /* 
  !  Navigation Bar Account Drop Down
*/
  .accountListDropDown {
    margin-top: 20px;
    border-radius: 8px;
    padding: 10px;
    background-color: rgba(49, 49, 49);
    box-shadow: 0 5px 10px rgba(23, 23, 23, 0.1);
    max-height: 220px;
    min-width: 180px;
    overflow-y: scroll;
    overflow-x: hidden;
    cursor: pointer;

    /* 
  !  Navigation Bar Account Drop Down Text
*/
    .accountListText {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .accountListDropDown:hover{
    transform: scale(1.1);
    transition: 0.3s ease;
  }
  /* 
!  Navigation Bar Dark Mode Style 
*/
  .navBarThemeToggleButtonText {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  /* 
!  Navigation Bar Theme Toggle Bar Style
*/
  .navBarThemeColToggleButton {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* 
!  Navigation Bar Account Navigator Style
*/
  .navBarThemeColAccountNavigator {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  /* 
!  Navigation Bar Drop Down Style
*/
  .navBarColDropDownNavigator {
    width: 10px;
    align-items: center;
    justify-content: center;
    margin: 30.3px 0px 0px 0px;
  }
  /* 
  !  Navigation Account Drop Down Style
*/
  .navBarAccountDropDown {
    justify-content: end;
    display: flex;
    padding-right: 30px;
    padding-top: 5px;
    z-index: 9000;
  }
}
